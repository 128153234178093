<style scoped>
.customer-pofile-card {
  text-align: center;
}

.get-all-contacts-btn {
  position: absolute;
  bottom: 40px;
  right: -25px;
}

.customer-pic {
  width: 90%;
  aspect-ratio: 1;
  border-radius: 100%;
  position: relative;
}

.customer-pic-banner {
  position: absolute;
  /* bottom: 0;
  right: 0; */
  text-align: center;
  /* margin-right: 3px; */
  margin-top: 3px;
}

.customer-old-pic {
  width: 90%;
  aspect-ratio: 1;
  border-radius: 100%;
}

.dummy-pic {
  width: 65%;
  /* aspect-ratio: 1; */
  border-radius: 100%;
}

.switch-text {
  /* text-decoration: underline; */
  /* text-decoration-color: #F18324; */
  color: #f18324;
  font-weight: 900 !important;
  text-decoration-thickness: 3px;
}

.toggle-switch-wrapper {
  background: #f5ab6a56;
  border-radius: 30px;
  max-width: fit-content;
  margin: 0 auto;
  padding: 8px 1px;
  position: relative;
  display: flex;
}

.toggle-switch-text {
  border-radius: 40px;
  padding: 6px;
  font-weight: 800;
  width: 130px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.toggle-switch-bg {
  background: #f18324;
  border-radius: 40px;
  position: absolute;
  /* width: 120px; */
  /* height: 42px; */
  width: 45%;
  height: 38px;
  z-index: 1;
  top: 6px;
  left: 2px;
  transition: transform 400ms ease-in-out;
}

.toggle-switch-bg-left {
  transform: translateX(3%);
}

.toggle-switch-bg-right {
  transform: translateX(115%);
}

.active-switch-text {
  color: #fff;
  transition: color 500ms ease-in-out;
}

.pre-verify-banner {
  color: #e50000;
  font-weight: 700;
  background-color: #e500002d;
  /* background-image:  radial-gradient(rgba(255, 255, 255, 0.434), rgba(255, 0, 0, 0.292)); */
}

.pre-check-btn {
  position: absolute;
  bottom: 0;
  right: 0;
}
.blocked-tag-wrapper {
  position: relative;
  overflow: initial;
}
.blocked-tag-star {
  position: absolute;
  top: -10px;
  right: -10px;
}
</style>
<template>
  <div>
    <DivLoader
      v-if="loading"
      :loading_type="
        'card-avatar,list-item-two-line,list-item-two-line,list-item-two-line'
      "
    ></DivLoader>
    <div v-else>
      <v-card elevation="5" class="pb-5 pt-4">
        <v-card-text class="customer-pofile-card">
          <div>
            <v-progress-circular
              :rotate="270"
              :size="200"
              :width="5"
              :value="profile_percentage"
              color="secondary_2"
            >
              <img
                v-if="profile_details.profile_pic"
                :src="profile_details.profile_pic"
                alt="customer pic"
                class="customer-pic"
              />
              <img
                v-else
                src="../../assets/images/user.svg"
                alt="customer pic"
                class="dummy-pic"
              />
            </v-progress-circular>
          </div>
          <!-- <div class="mt-3 green--text font-weight-bold">
            <span class="text-h6 font-weight-black"
              >{{ profile_details.profile_percentage }}%</span
            >
            Profile Completed
          </div> -->
          <div class="my-3">
            <p class="text-caption">Profile Status</p>
            <p v-if="profile_details.is_business_loan_eligible">
              <v-chip class="blue--text font-weight-bold blue lighten-4">
                <!-- @click="openUpdateLoanStatusDialog()" -->
                {{ profile_details.profile_status }}
              </v-chip>
            </p>
            <div v-else class="center-text">
              <v-tooltip left bottom class="d-flex">
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="d-flex align-center mt-1 justify-center"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <div id="status-v-chip">
                      <v-chip outlined color="red" class="blocked-tag-wrapper">
                        <div
                          v-if="
                            profile_details.profile_status ==
                              'Rejected by Cashpo'
                          "
                          class="blocked-tag-star"
                        >
                          <v-icon color="error">
                            mdi-star-circle
                          </v-icon>
                        </div>
                        <v-icon small color="red" class="pr-1"
                          >mdi-cancel</v-icon
                        >
                        Blocked
                      </v-chip>
                    </div>
                    <div>
                      <v-icon class="ml-2" @click="openConfirmRemove()"
                        >mdi-refresh</v-icon
                      >
                    </div>
                  </div>
                </template>
                <span>
                  <ul v-for="item in profile_details.blocked_at" :key="item">
                    <li v-if="item == 'No Entity Type'">
                      No Business Registration
                    </li>
                    <li v-else>{{ item }}</li>
                  </ul>
                </span>
              </v-tooltip>
            </div>
          </div>
          <!-- <div class="my-3">
            <p class="text-caption">Loan Status</p>
            <p v-if="profile_details.loan_status">
              <v-chip class="purple--text font-weight-bold purple lighten-4">
                {{ profile_details.loan_status }}
              </v-chip>
            </p>
            <p v-else>
              -
            </p>
          </div> -->
          <!-- <div class="my-4" v-if="profile_details.loan_counts">
            <p class="text-caption">Loan count</p>
            <div class="">
              <v-chip class="green lighten-4 mr-1">
                <v-icon color="white" class="mr-1 success--text"
                  >mdi-check</v-icon
                >
                <div class="success--text font-weight-black text-body-1">
                  {{ profile_details.loan_counts.completed }}
                </div>
              </v-chip>
              <v-chip class="red lighten-4 ml-1">
                <v-icon color="white" class="mr-1 error--text"
                  >mdi-close</v-icon
                >
                <div class="error--text font-weight-black text-body-1">
                  {{ profile_details.loan_counts.rejected }}
                </div>
              </v-chip>
            </div>
          </div> -->
          <div>
            <div>
              <p class="text-caption">Lead No.</p>
              <p class="font-weight-black">{{ profile_details.customer_id }}</p>
            </div>
            <div class="font-weight-bold text-h6 black--text">
              {{ profile_details.name }}
            </div>
            <div class="black--text">{{ profile_details.mobile }}</div>
            <div class="black--text mb-3">
              {{ profile_details.email }}
            </div>
            <div class="mt-3">
              <p class="text-caption">Signed-up At</p>
              <div v-if="profile_details.created" class="font-weight-black">
                <p>
                  {{ new Date(profile_details.created).toLocaleTimeString() }},
                </p>
                <p>
                  {{ new Date(profile_details.created).toDateString() }}
                </p>
              </div>
              <p v-else class="font-weight-black">
                -
              </p>
            </div>
            <div class="mt-3">
              <p class="text-caption">Comment</p>
              <p
                v-if="profile_details.internal_comment"
                class="font-weight-black"
              >
                {{ profile_details.internal_comment }}
              </p>
              <p v-else class="font-weight-black">
                -
              </p>
            </div>
            <div class="mt-3 mb-1">
              <p class="text-caption">Follow-up Date</p>
              <div
                v-if="profile_details.follow_up_date"
                class="font-weight-black"
              >
                <p>
                  {{ new Date(profile_details.follow_up_date).toDateString() }}
                </p>
              </div>
              <div v-else class="font-weight-black">
                -
              </div>
            </div>
            <div class="my-2">
              <v-btn
                outlined
                color="primary"
                @click="openUpdateLoanStatusDialog()"
              >
                Update Status
              </v-btn>
            </div>
            <div class="mb-2">
              <v-btn outlined color="primary" @click="openTrackDailog()">
                User Status Track
              </v-btn>
            </div>
            <div>
              <v-btn
                v-if="show_allot"
                outlined
                color="success"
                @click="openDisbursalForm()"
              >
                Allot NBFC
              </v-btn>
            </div>
            <div class="mt-2" v-if="show_pdf_btn">
              <v-btn outlined color="secondary_2" @click="generatePdf()">
                Generate PDF
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <v-dialog v-model="show_confirm_remove_dialog" width="500">
      <v-card>
        <v-card-title
          class="primary_2 d-flex justify-space-between font-weight-bold white--text"
        >
          Confirmation
          <v-btn
            text
            fab
            small
            dark
            @click="show_confirm_remove_dialog = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-3 text-body-1 font-weight-bold text-center">
          Are you sure you want to change Block status?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="confirm_btn_loader"
            :disabled="confirm_btn_loader"
            small
            class="success white--text"
            @click="removeBlock()"
          >
            Yes
          </v-btn>
          <v-btn
            :loading="confirm_btn_loader"
            :disabled="confirm_btn_loader"
            small
            class="error white--text"
            @click="show_confirm_remove_dialog = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <UpdateLoanStatusDialog
      ref="update_loan_status_dialog"
      @reload="getBusinessDetails"
    />
    <DisbursalForm ref="disbursal_form" @reload="getBusinessDetails" />
    <TrackDetailsDialog ref="track_detatils_dialog" />
  </div>
</template>
<script>
export default {
  props: ["data"],
  components: {
    UpdateLoanStatusDialog: () =>
      import("@/components/business-loan/dialog/UpdateLoanStatus.vue"),
    DisbursalForm: () =>
      import("@/components/business-loan/dialog/DisbursalForm.vue"),
    TrackDetailsDialog: () =>
      import("@/components/business-loan/dialog/TrackDetails.vue"),
  },
  data() {
    return {
      loading: false,
      profile_progress: 20,
      kyc_profile_image_url: "",
      doc_profile_image_url: "",
      alternate_mobile_dialog_data: {
        flag: false,
        id: null,
        alternate_number: "",
        action: "",
      },
      alternate_numbers: [],
      pre_verification_dialog_data: {
        flag: false,
        loan_id: null,
        data: [],
      },
      show_pre_check_btn: false,

      profile_details: [],
      profile_percentage: 0,
      show_confirm_remove_dialog: false,
      confirm_btn_loader: false,
    };
  },
  mounted() {
    this.getBusinessDetails();
  },
  created() {},
  methods: {
    getBusinessDetails() {
      const self = this;
      let look_up_key = `${self.decrypt(this.$route.params.id)}/`;
      const successHandler = (response) => {
        // console.log("profile", response);
        this.profile_details = response.data.result;
      };
      const finallyHandler = () => {};
      self.GET_request(
        self,
        self.$urls.GET_BUSINESS_PROFILE_CARD_DETAILS,
        look_up_key,
        null,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    removeBlock() {
      const self = this;
      self.confirm_btn_loader = true;

      let customer_id = self.decrypt(this.$route.params.id);

      const successHandler = () => {
        this.getBusinessDetails();
        self.confirm_btn_loader = false;
        this.show_confirm_remove_dialog = false;
      };
      const failureHandler = () => {
        self.confirm_btn_loader = false;
      };
      self.request_POST(
        self,
        self.$urls.REMOVE_BLOCK_STATUS + `${customer_id}/`,
        null,
        successHandler,
        null,
        failureHandler,
        null
      );
    },
    openUpdateLoanStatusDialog() {
      this.$refs.update_loan_status_dialog.openUpdateStatusDialog(
        this.profile_details.profile_status
      );
    },
    openDisbursalForm() {
      this.$refs.disbursal_form.openDisbursalDetailsDialog();
    },
    openTrackDailog() {
      this.$refs.track_detatils_dialog.openUserTrackDetails();
    },
    openConfirmRemove() {
      this.show_confirm_remove_dialog = true;
    },
    generatePdf() {
      window.open(
        `${this.$urls.BASE_URL}${
          this.$urls.GENERATE_BUSINESS_LOAN_PDF
        }${this.decrypt(this.$route.params.id)}/`,
        "_blank"
      );
    },
  },
  computed: {
    show_pdf_btn() {
      if (
        this.profile_details.profile_status == "Business Details" ||
        this.profile_details.profile_status == "Applied" ||
        this.profile_details.profile_status == "Under Process"
      ) {
        return true;
      } else {
        return false;
      }
    },
    show_allot() {
      if (
        this.profile_details.profile_status == "Disbursed" ||
        this.profile_details.profile_status == "Under Process"
      ) {
        return false;
      } else {
        return true;
      }
    },
    toggleSwitchBg() {
      let a = "toggle-switch-bg";

      if (this.data == false) {
        a = "toggle-switch-bg toggle-switch-bg-left";
        return a;
      } else {
        a = "toggle-switch-bg toggle-switch-bg-right";
        return a;
      }
    },
    basicSwitchActive() {
      let a = "";
      if (this.data == false) a = "active-switch-text";
      return a;
    },
    loanSwitchActive() {
      let a = "";
      if (this.data == true) a = "active-switch-text";
      return a;
    },
    toggleSwitch: {
      get() {
        return this.data;
      },
      set(newValue) {
        this.$emit("toggleDetails");
      },
    },
    activeBasic() {
      let a = "";
      if (this.data == false) a = "switch-text";
      return a;
    },
    activeLoan() {
      let a = "";
      if (this.data == true) a = "switch-text";
      return a;
    },
  },
};
</script>
